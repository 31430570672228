import React, { Component } from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem } from
"mdbreact";
import imageOne from './../support/assets/images/carrouselHome/carr-one.JPG'
import imageTwo from './../support/assets/images/carrouselHome/two.JPG'
import imageThree from './../support/assets/images/carrouselHome/three.JPG'
import imageFour from './../support/assets/images/carrouselHome/four.JPG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faYoutube} from '@fortawesome/free-brands-svg-icons'
// import { Link } from 'react-router-dom'
// import Countdown from 'react-countdown-now'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import Link from './Link'
// import Link from './Link'
var text = 'Assalamualaikum , Saya pengin tanya tanya tentang PPRQ'
text = text.replace(' ', '%20')
var nomor = '6285643191642'



class Jumbotron extends Component {
    state={
        imageCarousel : [
            imageOne,imageTwo,imageThree,imageFour
        ]
    }
    renderCarouselItem=()=>{
        var jsx = this.state.imageCarousel.map((val,index) => {
            return(
                <MDBCarouselItem itemId={index+1}>
                    <div className='carousel-image-container'>
                        <img
                            className="d-block carousel-image"
                            src={val}
                            alt="Third slide"
                        />
                    </div>
                </MDBCarouselItem>
            )
        })
        return jsx
    }

    renderCarousel =() => {
        return(
            <MDBCarousel
                activeItem={1}
                length={4}
                showControls={true}
                showIndicators={true}
                className="z-depth-1"
            >
                <MDBCarouselInner>
                {
                    this.renderCarouselItem()
                }
                
                </MDBCarouselInner>
            </MDBCarousel>
        )
    }

    render() {
        return (
            <div style={{backgroundColor:'#006b53'}} className='container-fluid pt-5 pb-5'>
            <div className='row px-5 py-5'>
                <div className='col-md-6'>
                <div className='jumbotron-text'>
                    Daftar Online, Bisaa !!
                </div> 
                <div className='jumbotron-second-text'>
                    Lebih Mudah dan Cepat
                </div> 
                <div className='jumbotron-second-text hashtag'>
                    Indonesia Lebih Nyantri
                </div> 
                {/* <div className='jumbotron-second-text'>
                    <Countdown
                        date={Date.now() + (Date.parse('01 Mar 2020 00:00:00 GMT') - Date.now())}
                        intervalDelay={0}
                        precision={3}
                        renderer={props => <div>{props.days + ' Hari , ' + props.hours + ' Jam , ' + props.minutes + ' Menit , ' + props.seconds + ' Detik ' }</div>}
                    />
                </div> */}
                <Link to='/register'>
                    {
                        ! this.props.dataAdmin.username ? 
                        <button disabled className='jumbotron-btn'>
                            DAFTAR SEKARANG
                        </button>
                        :
                        null
                        


                    }
                </Link>
                <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={`https://wa.me/${nomor}?text=${text}`}>
                <div className='jumbotron-btn-second' style={{display:'inline-block' ,textAlign:'center',paddingTop:'13px'}}>
                    CARI TAHU DULU
                </div>
                </a>
                </div>
                <div className='col-md-6' >
                <div className='carousel-jumbotron mt-5'>
                    {this.renderCarousel()}
                </div>
                <div className='carousel-socmed-container mt-4' >
                    <div className='container-logo'>
                        <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/RoudhotulQuranSirau/`}>
                        <FontAwesomeIcon icon={faFacebookSquare} />
                        </a>
                    </div>
                    <div>
                        <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/pprqsirau_ciwarak/`}>
                        <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                    <div>
                        <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/pprqputra/`}>
                        <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                    <div>
                        <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/channel/UCiCKLmUcmKpDfDpdK-Q2lzQ`}>                   
                        <FontAwesomeIcon icon={faYoutube} />
                        </a>
                    </div>
                </div>
                </div>
            </div>

        </div>
            // <div className='home-jumbotron'>
            //     <div className='jumbotron-text'>
            //         Daftar Online, Bisaa !!
            //     </div> 
            //     <div className='jumbotron-second-text'>
            //         Kapanpun dan Dimanapun
            //     </div> 
            //     <div className='jumbotron-second-text hashtag'>
            //         Pendaftaran Dibuka Tanggal 1 Maret 2020
            //     </div> 
            //     <div className='jumbotron-second-text'>
            //         <Countdown
            //             date={Date.now() + (Date.parse('01 Mar 2020 00:00:00 GMT') - Date.now())}
            //             intervalDelay={0}
            //             precision={3}
            //             renderer={props => <div>{props.days + ' Hari , ' + props.hours + ' Jam , ' + props.minutes + ' Menit , ' + props.seconds + ' Detik ' }</div>}
            //         />
            //     </div>
            //     <button disabled className='jumbotron-btn'>
            //         DAFTAR SEKARANG
            //     </button>
            //     <a style={{textDecoration:'none'}} target="_blank" rel="noopener noreferrer" href={`https://wa.me/085113235516?text=${text}`}>
            //     <div className='jumbotron-btn-second' style={{display:'inline-block' ,textAlign:'center',paddingTop:'13px'}}>
            //         CARI TAHU DULU
            //     </div>
            //     </a>
            //     <div className='carousel-jumbotron'>
            //         {this.renderCarousel()}
            //     </div>
            //     <div className='carousel-socmed-container'>
            //         <div className='container-logo'>
            //             <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/RoudhotulQuranSirau/`}>
            //             <FontAwesomeIcon icon={faFacebookSquare} />
            //             </a>
            //         </div>
            //         <div>
            //             <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/pprqsirau_purwokerto/`}>
            //             <FontAwesomeIcon icon={faInstagram} />
            //             </a>
            //         </div>
            //         <div>
            //             <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.instagram.com/pprqputra/`}>
            //             <FontAwesomeIcon icon={faInstagram} />
            //             </a>
            //         </div>
            //         <div>
            //             <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/channel/UCiCKLmUcmKpDfDpdK-Q2lzQ`}>                   
            //             <FontAwesomeIcon icon={faYoutube} />
            //             </a>
            //         </div>
            //     </div>
            // </div>
        )
    }
    
}

function mapStateToProps(state) {
    return {
        dataAdmin : state.admin.dataAdmin
    };
}


export default connect(mapStateToProps)(Jumbotron);


