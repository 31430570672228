import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import Image1 from './../../support/assets/images/gallery/gallery_1.jpeg'
import Image2 from './../../support/assets/images/gallery/gallery_2.jpeg'
import Image3 from './../../support/assets/images/gallery/gallery_3.jpeg'
import Image4 from './../../support/assets/images/gallery/gallery_4.jpeg'
import Image5 from './../../support/assets/images/gallery/gallery_5.jpeg'

const items = [
  {
    src: Image1
  },
  {
    src :Image2 
  },
  {
    src : Image3 
  },
  {
    src : Image4 
  },
  {
    src : Image5
  }
];

const Gallery = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img style={{width : "100%" , objectFit : "cover", objectPosition: "middle" , height : "70vh"}} src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <div>
        
        <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
        </Carousel>
    </div>
  );
}

export default Gallery;