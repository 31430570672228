import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class YoutubeCiwarak extends Component {
    render() {
        return (
            <div style={{borderBottom : "1px solid #f1f1f1"}}>
                <div className='container yt-section'>
                    <h1 className='center font-green-dark bold yt-hide'>PPRQ 2 Ciwarak</h1>
                    <div className='container text-yt'>
                        <div className='tanggal-yt mt-3'>PPRQ TV | 15 April 2020 </div>
                        <div className='judul-yt mt-1'>Pondok Untuk Mahasiswa Purwokerto (PPRQ 2)</div>
                        <div className='deskripsi-yt mt-3'>
                            Bagi teman teman mahasiswa Purwokerto, bisa banget mondok di PPRQ 2 Ciwarak dan tetep fokus kuliah, karena kita memiliki sistem pendidikan yang relevan terhadap dunia kemahasiswaan, tempat yang strategis, dan memiliki fasilitas fasilitas penunjang bagi mahasiswa seperti wifi dan parkiran luas
                        </div>
                        <div className='jumbotron-btn yt-btn my-3'>
                            <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.pprqsirau.com/register`}>
                            DAFTAR SEKARANG JUGA

                            </a>
                        </div>
                    </div>
                    <div className='container video-yt'>
                        <ReactPlayer 
                        width='100%'
                        height='100%'
                        url='https://www.youtube.com/watch?v=w-k2jaRACdY&'
                        controls/>
                    </div>
                    
                    
                </div>
            </div>
        )
    }
}

export default YoutubeCiwarak;
