var api;
if(window.location.hostname === 'localhost'){
     api = 'https://api.pprqsirau.com/';
}else if(window.location.hostname === "pprqsirau.com"){
     api = 'https://api.pprqsirau.com/';
}else{
     api = 'https://api.pprqsirau.com/';
}
// export const koneksi = `${api}`



// const development = 'http://localhost:4000/'
// const production = 'https://api.pprqsirau.com/'
export const urlApi = api
export const key = 'aa123123'
// export const forms_santri = 'id,nama_lengkap,nama_panggilan,kelamin,tanggal_lahir,anak_ke,jumlah_saudara,jenjang_pendidikan,nisn'.split(',')
// export const forms_santri =[
//     {
//         field : 'Nama Lengkap',
//         type :'text',
//         col : 'nama_lengkap'
//     },
//     {
//         field : 'Nama Panggilan',
//         type :'text',
//         col : 'nama_panggilan'
//     },
//     {
//         field : 'Jenis Kelamin',
//         type :'dropdown',
//         option : ['pria','wanita'],
//         col : 'kelamin'
//     },
//     {
//         field : 'Tanggal Lahir',
//         type :'date',
//         col : 'tanggal_lahir'
//     },
//     {
//         field : 'Anak Ke',
//         type :'number',
//         col : 'anak_ke'
//     },
//     {
//         field : 'Jumlah Saudara',
//         type :'number',
//         col : 'jumlah_saudara'
//     },
//     {
//         field : 'Jenjang Pendidikan',
//         type :'dropdown',
//         col : 'jenjang_pendidikan'
//     }
// ] 
// export const forms_orang_tua ='nama_ayah,no_kk,no_ktp_ayah,pekerjaan_ayah,penghasilan_ayah,pend_ayah,status_ayah,nama_ibu,no_ktp_ibu,pekerjaan_ibu,penghasilan_ibu,pend_ibu,status_ibu'.split(',')
// export const forms_kontak = ' alamat,propinsi,kabupaten,kecamatan,kode_pos,no_ayah,no_ibu'
