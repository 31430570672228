import React, { Component } from 'react'
import './../support/css/footer.css'
class Footer extends Component {
    render() {
        return (
            <div id='footer' className='footer-container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='title-text'>
                                Alamat
                            </div>
                            <div className='desc-text mt-3 mt-md-4'>
                             (PPRQ Pusat)   Desa Sirau RT.02 / RW.02, Kemranjen , Banyumas, Kabupaten Banyumas, Jawa Tengah 53194
                            </div>
                            <div className='desc-text mt-3 mt-md-4'>
                            (PPRQ 2) Jalan Raya Karanggintung, Karanggintung, Sumbang, Ciwarak, Karanggintung, Kec. Sumbang, Kabupaten Banyumas, Jawa Tengah 53183
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='title-text mt-5 mt-md-0'>
                                Contact
                            </div>
                            <div className='desc-text mt-3 mt-md-4'>
                                +62 851-132-35516
                            </div>
                            <div className='desc-text mt-3 mt-md-4'>
                                +62 822-4369-3459
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 title-text'>
                        © 2019 Hacker Team PPRQ 1.6 All Rights Reserved
                        </div>
                        <div className='col-md-6 title-text'>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default Footer;
