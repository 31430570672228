import React, { Component } from 'react'
import StepOne from './StepOne';
import './../../support/css/register.css'
import StepTwo from './StepTwo';
import Fade from 'react-reveal/Fade'
import StepThree from './StepThree';
import StepFour from './StepFour';
import { Prompt} from 'react-router-dom'
import NotFound from '../../components/NotFound';
import {Helmet} from 'react-helmet'
const user  = {
    role : 2
}


export default class RegisterOnline extends Component {
    state={
        step :1 ,
        dataSantri : [],
        dataAyah : [],
        dataIbu : [],
        dataAlamat : [],
        prompt : true
    }
    componentWillMount() {
        onbeforeunload = e => "Don't leave"
    }
    componentWillUnmount() {
        onbeforeunload = null
    }
    next = () => {
        this.setState({step : this.state.step + 1})
    }
    back = () => {
        this.setState({step : this.state.step - 1})
    }
    getDataSantri = (data) => {
        this.setState({dataSantri : data})
    }

    makePromptFalse = () => {
        this.setState({prompt : false})
    }

    render() {
        if(user.role !== 2){
            return(
                <NotFound/>
            )
        }
        return (
            <React.Fragment>
                {
                    this.state.prompt 
                    ?
                    <Prompt
                      message='You have unsaved changes, are you sure you want to leave?'/> 
                    :
                    null
                }
                
                <div style={{width : '100%'}} className='reg-container'>
                    <Helmet>
                        <title>Daftar Online Santri PPRQ</title>
                    </Helmet>

                    {
                        this.state.step === 1 ?
                        <Fade> <StepOne getData={this.getDataSantri}  next={this.next}/> </Fade>
                        : this.state.step===2 ?
                        <StepTwo back={this.back} next={this.next} />
                        : this.state.step === 3 ? 
                        <StepThree back={this.back} next={this.next}/>
                        : <StepFour back={this.back} prompt={this.makePromptFalse}/>
                    }

                </div>
            </React.Fragment>
        )
    }
}
