import React, { Component } from 'react'
import './../../support/css/homeSection.css'
import MyLink from './../../components/Link'

export default class homeSection extends Component {
    render() {
        return (
            // <div className='container-section gold'>
            //     <div className='text-section font-50 bold'>
            //         Ingin Mondok Sambil Sekolah?
            //     </div>
            //     <div className='text-section  font-30 '>
            //         Kamu Bisa Daftar di Sekolah Formal di Sekitar Pondok PPRQ
            //     </div>
            //     <div className='btn circle'>
            //         Lihat Daftar Sekolah Formal
            //     </div>
            // </div>
            <div className='container-fluid'> 

            <div className='row justify-content-center gold py-5'>    
                 <div className='col-md-8'>
                     <div className='text-center text-section font-50 bold'>
                     Ingin Mondok Sambil Sekolah?
                     </div>
                     <div className='text-center text-section  font-30'>
                     Kamu Bisa Daftar di Sekolah Formal di Sekitar Pondok PPRQ
                     </div>
                     <div className='row justify-content-center'>
                         <MyLink to='/daftar-sekolah'>
                            <div style={{color:'black'}}  className='text-center btn circle'>
                            Lihat Daftar Sekolah Formal
                            </div>
                         </MyLink>
                     </div>
                 </div>
            </div>
            </div>
        )
    }
}
