import React, { Component } from 'react';
import { Route,Switch } from 'react-router-dom' 
import HomePage from './pages/home/index'
import RegisterPage from './pages/daftarOnline'
import Navbar from './components/navbar'
import Footer from './components/Footer'
import LoginAdmin from './pages/login'
import RegSuccess from './pages/daftarOnline/RegSuccess'
// import ManageSantri from './pages/admin/ManageSantri'
// import ManageKelas from './pages/admin/ManageKelas'
// import ManageKamar from './pages/admin/ManageKamar';
// import DetailKelas from './pages/admin/DetailKelas';
// import DaftarSantriKelas from './pages/admin/DaftarSantriKelas';
import PageNotFound from './components/NotFound'
// import Sidebar from './components/Sidebar'
import Dashboard from './pages/admin/Dashboard';
// import Cropper from './components/tryCropper'
import Axios from 'axios';
import { urlApi } from './support/constant';
import {connect} from 'react-redux'
import {getCookie} from './support/functions/GetCookies'
import {onLoginAdminSuccess} from './redux/action/loginAdmin'
import DaftarSekolah from './pages/home/DaftarSekolah';
import LoadingPage from './components/LoadingPage';
import { RaportSantri } from './pages/hasilRaport/RaportSantri';
import UserDetail from './pages/userDetail/userDetail';
import EditUser from './pages/userDetail/EditUser';
import PrepareYourData from './pages/daftarOnline/PrepareYourData';
import Raport from './pages/DownloadPdf/Raport';
import Login from './pages/santri/Login';
import DownloadBrosur from './pages/home/DownloadBrosur';
import LandingPageSantri from './pages/santri/LandingPageSantri';
import Donation from './pages/donation/donation';
import Pay from './pages/donation/pay';
import DonasiUang from './pages/donation/donasiUangForm';
import DonasiMaterial from './pages/donation/donasiMaterial';
import OrderCreated from './pages/donation/orderCreated';
import OrderMoney from './pages/donation/orderMoneyCreated';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-173537824-2');
ReactGA.pageview(window.location.pathname + window.location.search);
// const coba = true
class App extends Component{
  state={
    header:true,
    checked : false
  }


  componentDidMount(){

   
    var {key} = require('./support/constant/index')    
    console.log(key)

    var username = getCookie()
    console.log(username)
    if(username !== undefined){
      const headers = {
        headers: { 
            'Authorization': `Bearer ${username}`,
        }
    }
      Axios.post(urlApi + 'admin/getuserbycookie',{},
      headers)
      .then((res) => {
        this.props.onLoginAdminSuccess(res.data.data[0])
        this.setState({checked:true})
      })
      .catch((err) => {
        console.log(err)
        this.setState({checked:true})
      })
    }else{
      this.setState({checked:true})
    }

  }


  render(){
    if(this.state.checked === false){
      return (
        <LoadingPage/>
      )
    }
    return(
      <div>
        {
          window.location.pathname.includes('/print-raport-pdf/') ?
          null:
          <Navbar />
        }
        {/* <Sidebar/> */}
        <Switch>
          {/* ======== DAFTAR ONLINE USER ====== */}
          <Route exact path='/' component={HomePage} />
          <Route exact path='/register-santri' component={RegisterPage} />
          <Route exact path='/register' component={PrepareYourData} />
          <Route exact path='/register-success' component={RegSuccess} />
          <Route exact path='/daftar-sekolah' component={DaftarSekolah} />
          <Route exact path='/raport-santri/:id' component={RaportSantri} />
          <Route exact path='/user-detail/:id' component={UserDetail} />
          <Route exact path='/edit-data-santri/:id' component={EditUser} />
          <Route exact path='/print-raport-pdf/:id' component={Raport} />
          <Route exact path='/alumni-mbangun-pprq' component={Donation} />
          <Route exact path='/alumni-mbangun-pprq/order-uang/:unique_id' component={Pay} />
          <Route exact path='/alumni-mbangun-pprq/order-material/:unique_id' component={OrderCreated} />
          <Route exact path='/alumni-mbangun-pprq/order-uang' component={OrderMoney} />
          <Route exact path='/alumni-mbangun-pprq/donasi-uang' component={DonasiUang} />
          <Route exact path='/alumni-mbangun-pprq/donasi-material' component={DonasiMaterial} />


          {/* ======== Admin Menu ================== */}
          <Route exact path='/logadm' component={LoginAdmin} />
          <Route path='/admin' component={Dashboard} /> 
          <Route path='/santri' component={LandingPageSantri} /> 


          {/* ======== Santri Login ============ */}
          <Route exact path='/login' component={Login} />
          <Route exact path='/download-brosur' component={DownloadBrosur} />

            
          {/* <Route exact path='/manage-santri' component={ManageSantri} /> */}
          {/* <Route exact path='/manage-kelas' component={ManageKelas} /> */}
          {/* <Route exact path='/manage-kamar' component={ManageKamar} /> */}
          {/* <Route exact path='/detail-kelas' component={DetailKelas} /> */}
          {/* <Route exact path='/daftar-santri-kelas' component={DaftarSantriKelas} /> */}

          <Route path='*' component={PageNotFound}/>
        </Switch>
        {
          window.location.pathname.includes('/print-raport-pdf/') ?
          null:
          <Footer/>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return{
      dataAdmin : state.admin.dataAdmin
  }
}

export default connect(mapStateToProps,{onLoginAdminSuccess})(App);
