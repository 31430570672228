import React, { Component } from 'react'
import {MDBCard} from 'mdbreact'
import { Badge, Button} from 'reactstrap';
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import { getHeaderAuth } from '../../support/functions/GetCookies';
import LoadingPage from '../../components/LoadingPage';
import TooltipText from '../../components/TooltipText';
import QueryString from 'querystring'
import { Link } from 'react-router-dom';
import MyLink from '../../components/Link';
import DataEmpty from '../../components/dataEmplty';

// const data = [
//     {nama : "jamaludin fikri", pend : "SMP/MTS" , panggilan : "fikri"},
//     {nama : "Putri Salsabila", pend : "SMA/SMK" , panggilan : "ceming"},
//     {nama : "Agus Nur Hidayat", pend : "SMA/SMK" , panggilan : "dayat"},
// ]


const listFilter = [
    {displayName : "SMA/SMK", real : "= 'SMA/SMK'", table : 'jenjang_pendidikan',color : "cyan"},
    {displayName : "SMP/MTS", real : "= 'SMP/MTS'", table : 'jenjang_pendidikan' ,color : "cyan"},
    {displayName : "SD/MI", real : "= 'SD/MI'", table : 'jenjang_pendidikan' ,color : "cyan"},
    {displayName : "Tidak Sekolah", real : " not in ('SMA/SMK','SMP/MTS','SMP / MTS','SD/MI')", table : 'jenjang_pendidikan' ,color : "cyan"},
    {displayName : "Santri Putra", real : "= 'Pria'", table : 'kelamin' ,color : "dark-green"},
    {displayName : "Santri Putri", real : "= 'Wanita'", table : 'kelamin' ,color : "dark-green"},
    {displayName : "Ayah Sarjana", real : " not in ('SD','SMP','SMA')", table : 'ua.pendidikan' ,color : "amber"},
    {displayName : "Ayah Tidak Sarjana", real : " not in ('S1','S2','S3')", table : 'ua.pendidikan' ,color : "amber"},
    {displayName : "Ibu Sarjana", real : " not in ('SD','SMP','SMA')", table : 'ui.pendidikan' ,color : "blue-grey"},
    {displayName : "Ibu Tidak Sarjana", real : "not in ('S1','S2','S3')", table : 'ui.pendidikan' ,color : "blue-grey"},
    {displayName : "Penghasilan Ayah Diatas 1 Jt", real : " not in ('Dibawah 500.000','500.000 - 1.000.000')", table : 'ua.penghasilan_ayah' ,color : "amber"},
    {displayName : "Penghasilan Ayah Dibawah 1 Jt", real : " not in ('1.000.000 - 2.900.000','3.000.000 - 4.900.000','5.000.000 - 10.000.000','Di atas 10.000.000')", table : 'ua.penghasilan_ayah' ,color : "amber"},
    {displayName : "Penghasilan Ibu Diatas 1 Jt", real : " not in ('Dibawah 500.000','500.000 - 1.000.000')", table : 'ui.penghasilan_ibu' ,color : "blue-grey"},
    {displayName : "Penghasilan Ibu Dibawah 1 Jt", real : " not in ('1.000.000 - 2.900.000','3.000.000 - 4.900.000','5.000.000 - 10.000.000','Di atas 10.000.000')", table : 'ui.penghasilan_ibu' ,color : "blue-grey"},
    {displayName : "Yatim", real : " = 0", table : 'ua.masih_hidup' ,color : "indigo"},
    {displayName : "Piatu", real : " = 0", table : 'ui.masih_hidup' ,color : "indigo"},
]
export default class ListSantri extends Component {
    state ={
        data : null,
        limit : null,
        loadindData :false,
        filterOpen : false,
        listFilter : listFilter,
        selectedFilter : [],
        loadMore : null,
        search : null
    }
    componentDidMount(){
        this.setState({limit : 99})
    }


    componentDidUpdate(prevProps,prevState){
        console.log(prevState.selectedFilter)
        console.log(this.state.selectedFilter)
        if(prevState.limit !== this.state.limit || prevState.selectedFilter !== this.state.selectedFilter || prevState.search !== this.state.search){
            this.getData()
        }
    }


    loadMoreBtnClick = () => {
        this.setState({loadindData : true})
        let {limit} = this.state
        limit = Number(limit) + 99
        this.setState({limit})
    }

    onClickListFilterSelected = (displayName) => {
        let displayNameList = this.state.selectedFilter.map(a => a.displayName);
        let index = displayNameList.indexOf(displayName)
        let array = this.state.selectedFilter.slice()
        array.splice(index,1)
        this.setState({selectedFilter : array})
    }

 
    getUrlQuery = () => {
        var desired = QueryString.parse(this.props.location.search.replace('?',''))
        console.log(desired)
        this.setState(desired)
    }

    renderFilterList = () => {
        let displayNameList = this.state.selectedFilter.map(a => a.displayName);
        return this.state.listFilter.map((val) => {
            if(displayNameList.includes(val.displayName)){
                return(
                    <Button color={val.color} onClick={() => this.onClickListFilterSelected(val.displayName)} className='rounded' style={{fontSize:'12px',padding:'3px'}}> {val.displayName}</Button>
                )
            }
            return(
                <Button outline color={val.color} onClick={() =>this.onClickListFilter(val)} className='rounded' style={{fontSize:'12px',padding:'3px'}}> {val.displayName}</Button>
            )
        })
    }

    getData = () => {
        this.setState({loadindData : true})
        const {limit,search} = this.state
        Axios.post(urlApi + `admin/getsantriwithlimitandfilter?limit=${limit}&search=${search}`,this.state.selectedFilter,getHeaderAuth())
        .then((res) => {
            console.log(res.data)
            if(!res.data.error) this.setState({data : res.data.data,loadindData : false , loadMore : res.data.nextPage})
        })
        .catch((err) => {
            console.log(err)
        })
    }

    onClickListFilter = (data) => {
        this.setState({selectedFilter : [...this.state.selectedFilter , data]})
    }
    
    togle = () => {
        this.setState({filterOpen : !this.state.filterOpen})
    }
    renderData = () => {
        if(this.state.data.length ===0 ) return <DataEmpty title='data not found' />
        return this.state.data.map((val) => {
            return(
                <div className="col-md-4 mt-2 mt-md-4">
                    <MDBCard className='p-2'>
                        <div className='row'>
                            <div className="col-3">
                                <img width='70px' style={{borderRadius:'70px',objectFit: "cover",objectPosition:'middle'}} height='70px' src={urlApi + val.foto} alt="broken"/>
                            </div>
                            <div className="col-8">
                                <div style={{cursor:'pointer'}}>
                                    <Link style={{color :"inherit"}} to={'/user-detail/' + val.id_user}>
                                        <TooltipText
                                            length={27}
                                            text={val.nama_lengkap + ' ('+val.nama_panggilan+ ')'}
                                        />
                                    </Link>
                                    {/* {val.nama_lengkap} ({val. nama_panggilan})  */}
                                </div>
                                <div>
                                    <Badge style={{cursor:'pointer'}} color="success">{val.jenjang_pendidikan}</Badge>
                                    <Badge style={{cursor:'pointer'}} className='ml-2' color="info">{val.pondok ? val.pondok : 'belum dapat kamar'}</Badge>
                                </div>
                                
                            </div>
                        </div>
                            {/* <div>
                                {val.nama_lengkap} ({val. nama_panggilan}) 
                            </div>
                            <div> 
                                <Badge style={{cursor:'pointer'}} color="success">{val.jenjang_pendidikan}</Badge>
                                <Badge style={{cursor:'pointer'}} className='ml-2' color="info">{val.pondok}</Badge>
                                <Badge style={{cursor:'pointer'}} className='ml-2' color="primary">Lihat Detail =></Badge>
                            </div> */}
                            
                    </MDBCard>
                </div>
            )
        })
    }

    onSearchClick = () => {
        let searhInput = this.refs.search.value
        if(searhInput){
            this.setState({search : searhInput})
        }
    }
    render() {
        if(this.state.data === null || this.state.loadindData) return <LoadingPage />
        return (
            <div>
                <div className='row justify-content-between px-3'>
                    <h3>Manage Santri</h3>
                    <MyLink to='/register'> <button style={{fontSize:'10px'}} className='btn btn-danger'> Tambah Data </button></MyLink>

                </div>
                <div className='row'>
                    <input type='text' placeholder='Cari Santri Berdasarkan Nama' ref='search' className='form-control col-6 ml-3' />
                    <Button  color='info' onClick={this.onSearchClick} className='rounded' style={{fontSize:'12px',padding:'5px'}}> Search</Button>
                    <Button  color='danger' onClick={() => {this.setState({search : null})}} className='rounded' style={{fontSize:'12px',padding:'5px'}}> Clear Search</Button>
                </div>
                <div style={{overflow : "auto",whiteSpace:'nowrap',padding : "10px"}}>
                    {this.renderFilterList()}
                </div>
                <div className="row">
                    {
                        this.renderData()
                    }
                </div>
                <div className='text-center'>
                    {
                        this.state.loadMore ?
                        this.state.loadindData ? 
                        <Button disabled onClick={this.loadMoreBtnClick} color='secondary' className='rounded mt-5' style={{fontSize:'12px',padding:'5px'}}> loading ...</Button>
                        :
                        <Button onClick={this.loadMoreBtnClick} color='success' className='rounded mt-5' style={{fontSize:'12px',padding:'5px'}}> load more ..</Button>
                        :
                        null
                        
                    }
                    
                </div>
            </div>
        )
    }
}

