import React, { Component } from 'react'
// import Carrousel from './../../components/carrouselHome'
import Jumbotron from './../../components/jumbotron';
import Section1 from './homeSection';
import Table from './../../components/TableBasic'
import Youtube from './YoutubeSection'
import MapSection from './MapSection'
import YoutubeCiwarak from './YoutubeCiwarak';
import Gallery from './Gallery';
import {Helmet} from "react-helmet";
class HomePage extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>PPRQ Sirau Kemranjen Banyumas</title>
                </Helmet>
                <Jumbotron/>   
                <Gallery />
                <Youtube/>
                <Section1/>   
                <YoutubeCiwarak />
                <Table/>
                <MapSection/>                

            
                
            </div>
        )
    }
}

export default HomePage
