import React, { Component } from 'react'
import {  Form, FormGroup, Label, Input,Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { saveDataSantri } from './../../redux/action'
import Modal from './../../components/Modal'
import DatePick from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
class StepOne extends Component {
    state = {
        selectedFileImage :null,
        error : '',
        modalOpen : false,
        nisn : null,
        selectedDate : null,
        nik : null,
        selectedPendidikan : null
    }

    componentWillMount(){
        if(this.props.dataSantri){
            this.setState({selectedFileImage : this.props.dataSantri.foto_profile , selectedDate : this.props.dataSantri.tanggal_lahir})
        }
    }

    handleOnChange = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({nisn : filter})
        }
        // alert(e.target.value)
    }

    handleOnChangeNik = (e) => {
        var filter = e.target.value
        // filter = filter.toString().split('')
        if(filter.length > e.target.maxLength){
            // alert('masuk')
            
        }
        else{
            
            this.setState({nik : filter})
        }
        // alert(e.target.value)
    }
    

    getDataSantri = () => {
        let nama,panggilan,gender,lahir,anak,jumlah,no_nisn,pendidikan,profile,tempat_instansi,no_nik;
        if(this.props.dataSantri){
            let {
                nama_lengkap,
                nama_panggilan,
                jenis_kelamin,
                // tanggal_lahir,
                anak_ke,
                jumlah_saudara,
                nisn,
                nik,
            } = this.props.dataSantri
            nama = this.refs.nama.refs.namaInner.value ? this.refs.nama.refs.namaInner.value : nama_lengkap ;
            panggilan = this.refs.panggilan.refs.panggilanInner.value ? this.refs.panggilan.refs.panggilanInner.value : nama_panggilan;
            gender = this.refs.gender.refs.genderInner.value ? this.refs.gender.refs.genderInner.value : jenis_kelamin
            lahir =this.state.selectedDate
            anak = this.refs.anak.refs.anakInner.value? this.refs.anak.refs.anakInner.value : anak_ke
            jumlah = this.refs.jumlah.refs.jumlahInner.value ? this.refs.jumlah.refs.jumlahInner.value : jumlah_saudara
            no_nisn = this.refs.nisn.refs.nisnInner.value ? this.refs.nisn.refs.nisnInner.value : nisn
            pendidikan = this.refs.pendidikan.refs.pendidikanInner.value
            profile = this.state.selectedFileImage;
            no_nik = this.refs.nik.refs.nikInner.value ? this.refs.nik.refs.nikInner.value : nik;
            tempat_instansi = this.refs.instansi.refs.instansiInner.value 
        }else{
            nama = this.refs.nama.refs.namaInner.value;
            panggilan = this.refs.panggilan.refs.panggilanInner.value
            gender = this.refs.gender.refs.genderInner.value;
            lahir = this.state.selectedDate
            anak = this.refs.anak.refs.anakInner.value;
            jumlah = this.refs.jumlah.refs.jumlahInner.value;
            no_nisn = this.refs.nisn.refs.nisnInner.value;
            pendidikan = this.refs.pendidikan.refs.pendidikanInner.value;
            tempat_instansi = this.refs.instansi.refs.instansiInner.value ? this.refs.instansi.refs.instansiInner.value : null;
            no_nik = this.refs.nik.refs.nikInner.value;
            profile = this.state.selectedFileImage;
        }

        // alert(tempat_instansi)


        if(nama && panggilan && gender && lahir && anak && jumlah && no_nisn && pendidikan && profile && no_nik && tempat_instansi){
            // alert(lahir)
            this.props.saveDataSantri(nama,panggilan,gender,lahir,anak,jumlah,no_nisn,pendidikan,profile,tempat_instansi,no_nik)
            this.props.next()
        }else{
            this.setState({modalOpen : true , error : "Semua Form Harus Diisi   "})
        }

    }

    
    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            console.log(event.target.files[0])
            if(event.target.files[0].size > 1050107 || event.target.files[0].type.split('/')[0] !== 'image' ){
                event.target.value = null
                this.setState({modalOpen : true , error : 'Pastikan File Berupa Image dan Kurang dari 1 mb'})
            }else{
                this.setState({selectedFileImage : event.target.files[0]})
            }
        }
      }

    render() {
        if(this.props.dataSantri){
            var { nama_lengkap,
                nama_panggilan,
                jenis_kelamin,
                // tanggal_lahir,
                anak_ke,
                jumlah_saudara,
                nisn,
                nik,
                instansi
                 } = this.props.dataSantri
        }
        
        return (
                <div className='d-flex justify-content-center'>
                <Modal 
                onClose={() => this.setState({modalOpen : false})} 
                title = 'Error' content ={this.state.error} 
                openModal ={this.state.modalOpen}/>

                <div className='col-md-6'>
                    <div className='d-flex justify-content-between p-4 top-info'>
                        <div>
                            <h3>Form 1 dari 4</h3>
                            <div> Data Santri </div>
                        </div>
                        <div className='icon-user'>
                            <FontAwesomeIcon icon={faUserTie} size='4x'/>
                        </div>
                    </div>  
                    <div className='cont-small p-4'>
                        <Form>
                            <FormGroup>
                                <Input type="text" defaultValue={nama_lengkap ? nama_lengkap : ''} ref='nama' innerRef='namaInner' placeholder='Nama Lengkap'/>
                            </FormGroup>
                            <FormGroup>
                                <Input type="text" ref='panggilan' defaultValue={nama_panggilan ? nama_panggilan :''} innerRef='panggilanInner' placeholder='Nama Panggilan'/>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='gender' md={3} sm={3}>Jenis Kelamin </Label>
                                <Col md={3} sm={3}>
                                    <Input id='gender' defaultValue={jenis_kelamin ? jenis_kelamin : '' } ref='gender' innerRef='genderInner' type='select'>
                                        <option>Pria</option>
                                        <option>Wanita</option>
                                    </Input>
                                </Col>
                                <Label for='ttl' md={3} sm={3}>Tanggal Lahir </Label>
                                <Col md={3} sm={3}>
                                    {/* <Input id='ttl' data-date-format="DD MM YYYY" defaultValue={tanggal_lahir ? tanggal_lahir : ''} ref='lahir' innerRef='lahirInner' type='date'/> */}
                                    <DatePick
                                    showYearDropdown
                                    selected={this.state.selectedDate}
                                    onChange= {(a) => this.setState({selectedDate : a})}
                                    placeholderText='DD-MM-YYYY'
                                    className='form-control'
                                    dateFormat="dd-MM-yyyy"
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={40}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='anak-ke' md={3} sm={3}>Anak Ke- </Label>
                                <Col md={3} sm={3}>
                                    <Input ref='anak' defaultValue={anak_ke ? anak_ke : ''} innerRef='anakInner' id='anak-ke' type='number'/>
                                </Col>
                                <Label for='dari' md={3} sm={3}>Jumlah Saudara</Label>
                                <Col md={3} sm={3}>
                                    <Input ref='jumlah' defaultValue={jumlah_saudara ? jumlah_saudara : ''}  innerRef='jumlahInner' id='dari' type='number'/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col md={6} sm={6}>
                                    <Input ref='nik' onChange={this.handleOnChangeNik} maxLength={16} defaultValue={nik ? nik : ''} value={this.state.nik} innerRef='nikInner' placeholder='NIK Santri' type='number'/>
                                </Col>
                                <Col md={6} sm={6}>

                                    <Input ref='nisn' onChange={this.handleOnChange} maxLength={10} defaultValue={nisn ? nisn : ''} value={this.state.nisn} innerRef='nisnInner' placeholder='NISN' type='number'/>
                                </Col>
                                
                            </FormGroup>
                            <FormGroup row>
                                <Col md={6} sm={6}>
                                    <Input  type='select' ref='pendidikan' innerRef='pendidikanInner' onChange={(e) => this.setState({selectedPendidikan : e.target.value})}  className='mt-md-0 mt-sm-0 mt-4'>
                                        <option value=''>Pendidikan Yang Diikuti</option>
                                        <option>SD/MI</option>
                                        <option>SMP/MTS</option>
                                        <option>SMA/SMK</option>
                                        <option>Mahasiswa</option>
                                        <option>Lainnya</option>
                                    </Input>
                                </Col>
                                {
                                    this.state.selectedPendidikan === 'SMA/SMK' || this.state.selectedPendidikan === 'SMP/MTS'?
                                    <Col md={6} sm={6}>
                                        <Input defaultValue={instansi ? instansi : null}  type='select' ref='instansi' innerRef='instansiInner'  className='mt-md-0 mt-sm-0 mt-4'>
                                            <option value=''>Pilih Instansi</option>
                                            {
                                                this.state.selectedPendidikan === 'SMA/SMK'? 
                                                ["SMA Ma'arif NU 1 Kemranjen" , "SMK PPRQ"].map((val) => {
                                                    return(
                                                        <option>{val}</option>
                                                    )
                                                })
                                                :
                                                this.state.selectedPendidikan === 'SMP/MTS'?
                                                ["SMP Ma'arif NU 2 Kemranjen"].map((val) => {
                                                    return(
                                                        <option>{val}</option>
                                                    )
                                                })
                                                : 
                                                null
                                            }
                                        </Input>
                                    </Col>
                                    :
                                    <Col md={6} sm={6}>
                                        <Input style={{display :'none'}}  type='select' ref='instansi' innerRef='instansiInner'  className='mt-md-0 mt-sm-0 mt-4'>
                                            <option value='others'>Pilih Instansi</option>
                                            {
                                                this.state.selectedPendidikan === 'SMA/SMK' ? 
                                                ["SMA Ma'arif NU 1 Kemranjen" , "SMK PPRQ"].map((val) => {
                                                    return(
                                                        <option>{val}</option>
                                                    )
                                                })
                                                :
                                                this.state.selectedPendidikan === 'SMP/MTS' ? 
                                                ["SMP Ma'arif NU 2 Kemranjen"].map((val) => {
                                                    return(
                                                        <option>{val}</option>
                                                    )
                                                })
                                                : 
                                                null
                                            }
                                        </Input>
                                    </Col>
                                }
                               
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={8} md={8}>
                                    <input onChange={this.onImageChange} type='file' accept="image/png, image/jpeg" ref='file' style={{display : 'none'}}/>
                                    <Input type='button' className='btn btn-primary' onClick={() => this.refs.file.click()}  value={ this.state.selectedFileImage ? this.state.selectedFileImage.name : 'Upload Foto Santri 3X4'} />
                                </Col>
                                <Col sm={'4'} md={'4'}>
                                    <Input type='button' className='btn btn-success' onClick={this.getDataSantri} value='Lanjut' />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </div>
                </div>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        dataSantri : state.form.dataSantri
    }
}


export default connect(mapStateToProps , {saveDataSantri})(StepOne)
