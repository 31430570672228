import React, { Component } from 'react'
import ReactPlayer from 'react-player'

class YoutubeSection extends Component {
    render() {
        return (
            <div className='container yt-section'>
                  <h1 className='center font-green-dark bold yt-hide'>Santri MI/SD PPRQ</h1>
                <div className='container video-yt'>
                    <ReactPlayer 
                    width='100%'
                    height='100%'
                    url='https://www.youtube.com/watch?v=9wMNEtBXkYw'
                    controls/>
                </div>
                <div className='container text-yt'>
                    <div className='tanggal-yt mt-3'>PPRQ Tv | 25 Agustus 2018 </div>
                    <div className='judul-yt mt-1'>Berkenalan dengan Santri MI/SD PPRQ</div>
                    <div className='deskripsi-yt mt-3'>
                        PPRQ Juga menyediakan Sistem pendidikan khusus bagi santri santri yang masih duduk di bangku sekolah dasar, mereka di kelompokan dalam satu kelompok khusus yang mempunyai jadwal kegiatan dan kewajiban yang berbeda dengan santri Umum.
                    </div>
                    <div className='jumbotron-btn yt-btn'>
                        <a style={{textDecoration:'none',color:'white'}} target="_blank" rel="noopener noreferrer" href={`https://www.youtube.com/channel/UCiCKLmUcmKpDfDpdK-Q2lzQ`}>
                        LIHAT YOUTUBE PPRQ

                        </a>
                    </div>
                </div>
                
            </div>
        )
    }
}

export default YoutubeSection;
