import React, { Component } from 'react'
import Icon from './../../support/assets/images/Icons/checklist.svg'
import { MDBCard, MDBCardBody, MDBCardText } from 'mdbreact'
import { Link } from 'react-router-dom'
import {Helmet} from 'react-helmet'

export default class PrepareYourData extends Component {
    render() {
        return (
            <div className="container my-5 py-5">
                <Helmet>
                    <title>Daftar Online Santri PPRQ</title>
                </Helmet>
                <div className='row justify-content-center'>
                    {/* <div className="col-3"> */}
                        <h3 className='text-center'>Siapkan Dokumen Berikut ini Sebelum Melanjutkan Pendaftaran</h3>
                    {/* </div> */}
                   
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-3">
                        <img width='100%' src={ Icon} alt=""/>
                    </div>
                </div>

                <div className="row justify-content-center mt-3">
                    <div className="col-md-4">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardText>
                                    <div>
                                        Foto Santri 3 X 4 (Max. 1 MB)
                                    </div>
                                    <div>
                                        Scan KK (Max. 1 MB)
                                    </div>
                                    <div>
                                        Scan KTP Ayah (Max. 1 MB)
                                    </div>
                                    <div>
                                        Scan KTP Ibu (Max. 1 MB)
                                    </div>
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-md-3">
                        <Link to='/register-santri'>
                            <input type='button' className="btn btn-success w-100" value='lanjutkan'>
                            </input>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}
