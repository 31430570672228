import React, { Component } from 'react'

export default class MapSection extends Component {
    render() {
        return (
            <div style={{borderTop : "1px solid #f1f1f1"}} className='container-fluid py-5 my-5'>
                <div className='row'>
                    <div className='col-md-6'>
                        <h3 className='text-center mb-4'>
                            PPRQ Pusat (Sirau)
                        </h3>
                        <iframe  title='PPRQ On Google Map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15818.454778408142!2d109.2886062!3d-7.6169445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5ff41c2b5e1b43a!2sPonpes+Putri+Roudhotul+Qur&#39;an!5e0!3m2!1sen!2sid!4v1560822818356!5m2!1sen!2sid" width="100%" height="450px" frameborder="0"  allowfullscreen></iframe>                
                    </div>
                    <div className='col-md-6 mt-3 mt-md-0'>
                        <h3 className='text-center mb-4'>
                            PPRQ 2 (Ciwarak)
                        </h3>
                        <iframe  title='PPRQ On Google Map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.7333536792703!2d109.24516781487675!3d-7.383750074731539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e655f11be36fc8b%3A0xe717c2741477f73a!2sPondok%20Pesantren%20Roudhotul%20Qur&#39;an%202%20Ciwarak%20Karanggintung%20Sumbang!5e0!3m2!1sen!2sid!4v1587039417959!5m2!1sen!2sid" width="100%" height="450px" frameborder="0"  allowfullscreen></iframe>                
                    </div>
                </div>

            </div>
        )
    }
}
