import React, { Component } from 'react';
import '../../../node_modules/react-vis/dist/style.css'
// import Plot from 'react-plotly.js';
import { RadialChart,Hint} from 'react-vis';
import Loading from './../../components/LoadingPage'
import {HorizontalBar} from 'react-chartjs-2'
import Axios from 'axios';
import { urlApi } from '../../support/constant';
import {getCookie, getHeaderAuth} from '../../support/functions/GetCookies'
import { connect } from 'react-redux';

// state = {

    // 0: {label: "1.000.000 - 2.900.000", angle: 226, total: 226, percent: 83}
    // 1: {label: "500.000 - 1.000.000", angle: 18, total: 18, percent: 7}
    // 2: {label: "3.000.000 - 4.900.000", angle: 20, total: 20, percent: 7}
    // 3: {label: "5.000.000 - 10.000.000", angle: 4, total: 4, percent: 1}
    // 4: {label: "Dibawah 500.000", angle: 5, total: 5, percen
//     dataHorizontal: {
//       labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue', 'Purple', 'Grey'],
//       datasets: [
//         {
//           label: 'My First Dataset',
//           data: [22, 33, 55, 12, 86, 23, 14],
//           fill: false,
//           backgroundColor: [
//             'rgba(255, 99, 132, 0.2)',
//             'rgba(255, 159, 64, 0.2)',
//             'rgba(255, 205, 86, 0.2)',
//             'rgba(75, 192, 192, 0.2)',
//             'rgba(54, 162, 235, 0.2)',
//             'rgba(153, 102, 255, 0.2)',
//             'rgba(201, 203, 207, 0.2)'
//           ],
//           borderColor: [
//             'rgb(255, 99, 132)',
//             'rgb(255, 159, 64)',
//             'rgb(255, 205, 86)',
//             'rgb(75, 192, 192)',
//             'rgb(54, 162, 235)',
//             'rgb(153, 102, 255)',
//             'rgb(201, 203, 207)'
//           ],
//           borderWidth: 1
//         }
//       ]
//     }
//   };


class Statistic extends Component {
    state={
        data : null,
        hint_0 : false,
        hint_1 : false,
        hint_2 : false,
        hint_3 : false,
        hint_4 : false,
        hint_5 : false,
        data_2 : null
    }
    componentDidMount(){

        const token = getCookie()
        if(token){
            const headers = getHeaderAuth()
            Axios.get(urlApi+'admin/getstatistics?id_user=' + this.props.dataAdmin.id,headers)
            .then((res) => {
                console.log(res.data)
                console.log(res.data.data.slice(-2))
                // console.log(data)
                this.setState({data:res.data.data.slice(0,-2) , data_2 : res.data.data.slice(-2)})
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

    renderBarChartMdb = () => {
        var title = ['Penghasilan Ayah','Penghasilan Ibu']
        var data = []
        title.forEach((val,index) => {
            var obj = {}
            var labels = this.state.data_2[index].map((val) => {
                return val.label
            })

            var counts = this.state.data_2[index].map((val) => {
                return val.total
            })

            obj.labels = labels
            obj.datasets = [
                {
                    label : val,
                    data : counts,
                    fill : false,
                    backgroundColor : [
                        "#FF5A5E",
                        "#5AD3D1",
                        "#FFC870",
                        "#A8B3C5",
                        "#616774",
                        "#DA92DB"
                    ],
                    borderColor : [
                        "#F7464A",
                        "#46BFBD",
                        "#FDB45C",
                        "#949FB1",
                        "#4D5360",
                        "#AC64AD"
                    ],
                    borderWidth : 1
                }
            ]

            data.push(obj)
        })

        return data.map((val,index) => {
            return(
                <div style={{border:'1px solid #eaedf0'}} className='col-md-6 text-center py-4' >
                    {title[index]}
                    <HorizontalBar
                        data={val}
                        options={{ responsive: true }}
                    />
                </div>
            )
        })
    }
    render() {
        // console.log(this.state['hint_1'])
        const title = [
            'Pekerjaan Ayah' , 'Pekerjaan Ibu','Pendidikan Ayah','Pendidikan Ibu','Jenis Kelamin','Jenjang Pendidikan'
        ]
        if(this.state.data === null){
            return(
                <Loading/>
            )
        }

        return (
            
            <div>
                <h1>
                    Statistic Data Santri PPRQ
                </h1>
                <div className='row justify-content-center'>
                   {
                       
                       this.state.data.map((val,index) => {
                        //    var data = []
                        //    val.forEach((obj) => {
                        //         obj['style'] = {color:'white'}
                        //         data.push(obj)    
                        //    })
                        //    console.log(data)
                           
                           return(
                            <div style={{border:'1px solid #eaedf0'}} className='col-md-6 text-center py-4' >
                                {title[index]}
                                <div className='row justify-content-center align-items-center'>
                                        <RadialChart
                                        // colorType={''}
                                        // colorDomain={[0, 100]}
                                        // colorRange={[0, 10]}
                                        data={val}
                                        width={300}
                                        height={300}
                                        showLabels
                                        onValueMouseOver={v => {
                                            var data = {
                                                'Label' : v.label,
                                                'Presentase' : v.percent,
                                                'x' : v.x,
                                                'y' : v.y,
                                                'count' :v.total
                                            }
                                            this.setState({['hint_'+index]:data})
                                            console.log(v)
                                        }}
                                        onSeriesMouseOut={v => this.setState({['hint_'+index]:false})}
                                        // labelsStyle ={color:'red'}
                                        // radius={150}
                                         >
                                        {this.state['hint_' + index] !== false && 
                                        <Hint value={this.state['hint_'+index]} > 
                                        <div style={{backgroundColor:'black',borderRadius:10}} className='p-2'>
                                            <div style={{fontSize:12}}> Presentase : {this.state['hint_'+index].Presentase}%</div>
                                            <div style={{fontSize:12}}> Frekuensi : {this.state['hint_'+index].count}</div>
                                            <div style={{fontSize:12}}> Label : {this.state['hint_'+index].Label}</div>
                                        </div>
                                        </Hint>}
                                        </RadialChart>
                                    </div>
                                </div>
                           )
                       })
                   }

                   
                   {
                       this.renderBarChartMdb()
                   }
                     
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}

export default connect(mapStateToProps)(Statistic);