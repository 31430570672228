import React from 'react';
import { Table,Nav,NavItem,NavLink,TabContent,TabPane } from 'reactstrap';
import classnames from 'classnames';
import { formatRupiah } from './../support/functions/index'

const DataMaker = (rincian,waktu,jumlah) => {
    return{
        rincian,waktu,jumlah
    }
}




class TableBasic extends React.Component {
    state={dataPutra : [
        DataMaker('Pendaftaran' ,'Sekali', 100000),
        DataMaker('Syahriyah' ,'Setiap Bulan' ,100000),
        DataMaker('Bisyaroh Diniyah' ,'Setiap Bulan', 15000),
        DataMaker('Makan (3 X 1)' ,'Setiap Bulan' ,315000),
        DataMaker('Jasa Kebersihan' ,'Setiap Tahun', 100000),
        DataMaker('Kalender' ,'Sekali', 25000),
        DataMaker('Jas Almamater' ,'Sekali', 150000),
        DataMaker('Kesehatan' ,'Setiap Tahun', 100000),
        // DataMaker('Pemakaian Dipan' ,'Sekali', 300000),
        // DataMaker('Pemakaian Almari' ,'Sekali', 125000),
        DataMaker('Jasa Air Minum' ,'Sekali', 100000),
        DataMaker('Seragam Olahraga' ,'Sekali', 120000),
    ],
    dataPutri : [
        DataMaker('Pendaftaran' ,'Sekali', 100000),
        DataMaker('Syahriyah' ,'Setiap Bulan' ,100000),
        DataMaker('Makan (2 X 1)' ,'Setiap Bulan' ,210000),
        DataMaker('Bisyaroh Diniyah' ,'Setiap Bulan', 15000),
        DataMaker('Jasa Kebersihan' ,'Setiap Tahun', 100000),
        DataMaker('Kalender' ,'Sekali', 25000),
        DataMaker('Almamater (Jas dan Kerudung)' ,'Sekali', 200000),
        DataMaker('Kesehatan' ,'Setiap Tahun', 50000),
        DataMaker('Pemakaian Dipan' ,'Sekali', 300000),
        DataMaker('Pemakaian Almari' ,'Sekali', 125000),
        DataMaker('Jasa Air Minum' ,'Sekali', 100000),
        DataMaker('Seragam Olahraga' ,'Sekali', 120000),
        
    ],
    dataCiwarak : [
        DataMaker('Pendaftaran' ,'Sekali', 100000),
        DataMaker('Operasional Pesantren' ,'Setiap Tahun' ,1200000),
        DataMaker('Makan (2 X 1)' ,'Setiap Bulan' ,210000),
        DataMaker('Akhirussanah' ,'Setiap Semester' ,200000),
        DataMaker('Bisyaroh Diniyah' ,'Setiap Tahun', 300000),
        DataMaker('Jas Almamater' ,'Sekali', 120000),
        DataMaker('Buku Santri, KTS dan Rapor Madin' ,'Sekali', 50000),
        DataMaker('Osaba & Hari Santri' ,'Sekali', 50000),
        DataMaker('Kalender' ,'Sekali', 20000),
        DataMaker('Jariyah (Minimal)' ,'Sekali', 100000),
    ],
    activeTab : '1'} 

    // renderDataTable = () => {
    //     var jsx = this.state.data.map((val,index) => {
    //         return(
    //             <tr>
    //                 <th scope='row'>{index+1}</th>
    //                 <td>{val.rincian}</td>
    //                 <td>{val.waktu}</td>
    //                 <td>{val.jumlah}</td>        
    //             </tr>
    //         )
    //     })
    //     return jsx
    // }

    renderSantriPutraBiaya = (data,gender) => {
        var total = 0
        for(var i = 0 ; i< data.length ; i++){
            total += data[i].jumlah
        }
        return(
            <div>
                <div className='font-green bold ml-2 bold pt-4 mb-4'>
                    Total Biaya Untuk Santri {gender} adalah {formatRupiah(String(total),'Rp') } <br/>
                </div>
                <Table  size='md'>
                        <thead>
                        <tr>
                            <th className='font-green bold'>No</th>
                            <th className='font-green bold'>Rincian Pembayaran</th>
                            <th className='font-green bold'>Waktu Pembayaran</th>
                            <th className='font-green bold'>Jumlah</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((val,index) => {
                            return(
                                <tr>
                                    <th className='font-black-natural-dark' scope='row'>{index+1}</th>
                                    <td className='font-black-natural-dark'>{val.rincian}</td>
                                    <td className='font-black-natural-dark'>{val.waktu}</td>
                                    <td className='font-black-natural-dark'>{formatRupiah(String(val.jumlah),'Rp') }</td>        
                                </tr>
                            )
                        })}
                        </tbody>
                </Table>
            </div>
        )
    }
    toggle = (id) => {
        this.setState({activeTab : id})
    }
  render() {
    return (
        <div className='container table-basic'>
            <h1 className='center font-green-dark bold'>Biaya Pendaftaran Santri</h1>
            <div className='Mycontainer'>
            <Nav tabs className='justify-content-center'>
                <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                >
                    Putra Sirau 
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                >
                    Putri Sirau
                </NavLink>
                </NavItem>
                <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                >
                    Ciwarak (Putra & Putri)
                </NavLink>
                </NavItem>
            </Nav>
            
            <TabContent className='pr-4 pl-4 table-scroll' style={{borderBottom:'0.5px solid #dee2e6', borderLeft:'0.5px solid #dee2e6',borderRight:'0.5px solid #dee2e6'}} activeTab={this.state.activeTab}>
                <TabPane tabId='1'>
                    {this.renderSantriPutraBiaya(this.state.dataPutra,"Putra")}
                </TabPane>
                <TabPane tabId='2'>
                    {this.renderSantriPutraBiaya(this.state.dataPutri, "Putri")}
                </TabPane>
                <TabPane tabId='3'>
                    {this.renderSantriPutraBiaya(this.state.dataCiwarak, "Ciwarak")}
                </TabPane>
            </TabContent>
                
            </div>
        </div>
    );
  }
}

export default TableBasic;


