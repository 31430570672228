import React, { Component } from 'react'
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle } from 'mdbreact'
import Axios from 'axios'
import { urlApi } from '../../support/constant'
import {onLoginAdminSuccess} from './../../redux/action/loginAdmin'
import {connect} from 'react-redux'
import Swal from 'sweetalert2'
import {key} from './../../support/constant/index'
import { Redirect } from 'react-router-dom'
import {Helmet} from 'react-helmet'


class Login extends Component {
    state = {
        username : '',
        password : ''
    }
    onLoginClick = () => {
        const data = {
            username :this.state.username,
            password :this.state.password
        }
        console.log(data)
        Axios.post(urlApi + 'santri/login',data)
        .then((res) => {
            console.log(res)
            if(res.data.data.length ===0){
                return Swal.fire('Username Or Password Invalid')
            }
            localStorage.setItem(key,res.data.token)
            this.props.onLoginAdminSuccess(res.data.data[0])
            window.location.reload()
        })
        .catch((err) => {
            console.log(err)
        })
    }
    render() {
        if(this.props.dataAdmin.username !== ''){
            return(
                <Redirect to={'/santri/detail/' + this.props.dataAdmin.id } />
            )
        }
        return (
            <div style={{height : '80vh'}} className='container-fluid py-5 gradient-green'>
                <Helmet>
                    <title>
                        Login Santri PPRQ
                    </title>
                </Helmet>
                <div style={{height : '80px'}}>
                </div>
                <div className="row justify-content-center py-5">
                    
                    <div className="col-md-4">
                        <MDBCard>
                            <MDBCardHeader>
                                <MDBCardTitle>
                                    Login Santri PPRQ
                                </MDBCardTitle>
                            </MDBCardHeader>
                            <MDBCardBody>
                                <input type='text' value={this.state.username} onChange={(e) => this.setState({username : e.target.value})} className='form-control mt-3' placeholder='Masukan Username' />
                                <input type='password' value={this.state.password} onChange={(e) => this.setState({password : e.target.value})} className='form-control mt-3' placeholder='Masukan Password' />
                                <input style={{fontSize:12,padding:10,margin:0}} type='button' onClick={this.onLoginClick} className='btn btn-primary mt-3 w-100' value='login' />
                            </MDBCardBody>
                        </MDBCard>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        dataAdmin : state.admin.dataAdmin
    }
}


export default connect(mapStateToProps,{onLoginAdminSuccess})(Login)
